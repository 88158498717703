import React from 'react'
import {  IconButton } from '@mui/material';
import Iconify from '../../../components/iconify';
import { useSettingsContext } from '../../../components/settings';

export function CollapseIcon() {
  const { themeLayout,onChangeLayout } = useSettingsContext();

  return (
    <IconButton   
      sx={{
        border: "1px solid rgba(145, 158, 171, 0.12)",
        alignSelf: "end",
        width:26,
        height:26,
        padding: 0.5,
        display: "flex",
        justifyContent: "center",
        alignItems:"center",
        position: "absolute",
        top: 36,
        right: -13,
        isolation: "isolate",
        zIndex:1201,
        backgroundColor:"white",
        ":hover": {
          backgroundColor: "#F4F6F8"
        }
      }} onClick={() =>{
        onChangeLayout({
          target:{
            value: themeLayout === "mini"? "vertical": "mini"
          }
        })
      }}
    >
      <Iconify icon={themeLayout === "mini"?"eva:arrow-ios-forward-fill" :"eva:arrow-ios-back-fill"} />
    </IconButton>
  )
}
